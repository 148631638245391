@import "./variables.scss";

.home-nav {
  margin-bottom: 40px;

  img {
    vertical-align: middle;
  }

  .nav-title {
    color: white;
    font-size: 28px;
    margin-left: 15px;
  }

  .nav-body {
    padding: 10px 0px;
    border-bottom: 0.4px solid white;

    .nav-link {
      color: white !important;
      font-size: 20px;
      margin: 5px 20px;
    }

    .nav-btn {
      margin-top: -5px;
      padding: 5px 20px;
      font-size: 20px;
    }
  }

  .navbar-toggler,
  .navbar-toggler.collapsed {
    border: none;
    color: white;

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  }

  .nav-logo {
    max-width: 150px;
  }
}

// Main Nav variant
.main-nav {
  .home-nav {
    .nav-title {
      color: $primary;
    }

    .nav-body {
      border-bottom: 0.4px solid $primary;
      .nav-link {
        color: $primary;
      }
      .nav-btn {
        border: 1px solid $primary;
        color: $primary;
      }
    }
  }
  .navbar-toggler,
  .navbar-toggler.collapsed {
    color: $primary;

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%289, 153, 235%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "lg")) {
  .home-nav {
    .nav-body {
      .nav-link {
        font-size: 18px;
        margin: 5px 12px;
      }

      .nav-btn {
        padding: 5px 15px;
        font-size: 18px;
      }
    }
  }
}

