@import "./variables.scss";

.page-card {
  margin-top: 30px;
  border-radius: 5px;
    padding: 40px;
  border: 1px solid #d2d6dc;
  box-shadow: 0px 2px 10px 2px rgba(66, 67, 71, 0.1);

  @media screen and (max-width: map-get($grid-breakpoints, "md")) {
    padding: 0px;
    border: none;
    box-shadow: none;
    margin-left: -5px;
    margin-right: -5px;
  }
}
