@import "./variables.scss";

.sidebar {
  border-right: 0.5px solid rgba(0, 0, 0, 0.1);
  height: 100%;

  .sidebar-link {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    font-size: 16px;
    margin-right: 20px;
    padding: 10px;
    color: black !important;

    .active {
      color: $primary;
    }
  }
}
