@import "./variables.scss";

.token-box {
  input {
    font-size: 28px;
    border: 1px solid $primary;
    text-align: center;
    padding: 30px 5px;
    letter-spacing: 1px;
    background-color: white !important;
  }

  button {
    background-color: $primary;
    border: 1px solid $primary;
    font-size: 30px;
    font-weight: 700;
    padding: 4px 15px;
  }

  @media screen and (max-width: map-get($grid-breakpoints, "md")) {
    input {
      font-size: 26px;
      padding: 20px 5px;
    }

    button {
      font-weight: 600;
      padding: 3px 12px;
      font-size: 26px;
    }
  }

  @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
    input {
      font-size: 20px;
      letter-spacing: 0.5px;
    }
    button {
      font-size: 24px;
      font-weight: 600;
      padding: 2px 8px;
    }
  }
}
