.confirm-payment {
    .confirm-payment-card {
        padding: 100px 20px;
    }
    .bottom-text {
        margin-top: 20px;
        text-align: center;
    }
    .spinner {
        margin-bottom: 30px;
    }
}