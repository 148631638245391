@import "./variables.scss";

* {
  font-family: "Source Sans Pro", sans-serif;
}

.App {
  .main {
    min-height: 85vh;
  }

  .home-hero {
    height: 100vh;
    background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.6) 33%
      ),
      url(../images/home_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .home-content {
    h1 {
      color: white;
    }
    p {
      color: white;
    }
  }

  .home-form-container {
    max-width: 350px;
  }

  .btn {
    border-radius: 4px;
  }

  .btn.disabled,
  .btn:disabled {
    opacity: 1;
  }

  input.error {
    border: 2px solid red;
  }

  @media screen and (max-width: map-get($grid-breakpoints, "xl")) {
    body {
      border: 1px solid green;
    }
  }
  @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
    body {
      border: 1px solid blue;
    }
  }
  @media screen and (max-width: map-get($grid-breakpoints, "md")) {
    body {
      border: 1px solid yellow;
    }

    .desktop {
      display: none;
    }
  }
  @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
    body {
      border: 1px solid red;
    }
  }
  @media screen and (max-width: map-get($grid-breakpoints, "xs")) {
    body {
      border: 1px solid antiquewhite;
    }
  }
}
