@import "./variables.scss";

.footer {
  background-color: #162431;
  padding: 15px;
  color: white;

  p a {
      color: white;
      font-size: 14px;
  }

  .nav-title {
    color: $primary;
    font-size: 28px;
    margin-left: 15px;
  }

  .nav-btn {
      margin-top: -5px;
      padding: 5px 20px;
      font-size: 20px;
    }

    .action-text {
        color: white;
        font-size: 22px;
        margin-right: 20px;
    }

    .bottom-nav {
        border-bottom: 1px solid rgba(255, 255, 255, 0.219);
    }

    .cols {
        margin-top: 30px;
    }

    .newsletter-input {
        margin-top: 25px;
        input {
            background-color: transparent;
            font-size: 20px;
            padding: 10px;
            border: none;
            border-bottom: 0.5px solid rgba(255, 255, 255, 0.575);
        }
        .newsletter-append {
            background-color: $primary;
            border: 1px solid $primary;
            color: white;
        }
    }
}
