.payment-info-box {
  max-width: 350px;
  margin: 0 auto;
  padding: 20px 0;

  h5 {
    font-size: 16px;
    font-weight: 200;
    margin-bottom: 2px;
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 25px;
  }

  input.amount-input {
    border-left: none;
  }

  .amount-input-box {
    padding-left: 5px;
  }

   input {
    margin-bottom: 10px;
    border: 1.2px solid rgba(6, 84, 116, 0.4);
    border-radius: 4px;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 2px;
    color: #424347;
  }

  input::placeholder {
    opacity: 0.4;
  }

  .input-group-text {
    background-color: white;
    font-size: 30px;
    color: grey;
    padding: 4px 5px 0px 5px;
    border: 1.2px solid rgba(6, 84, 116, 0.4);
    border-right: none;
    display: inline-block;
    font-size: 28px;
    transform: scale(1.4, 1);
  }
}
