.transaction-data {
  background: #ffffff;
  border: 1.2px solid #d2d6dc;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;

  h5 {
      margin-bottom: 0px;
  }

  .title {
    color: #424347;
    opacity: 0.6;
  }

  .value {
    font-weight: 600;
    color: #424347;
  }

  .currency {
    color: green;
    font-weight: bold;
    opacity: 0.7;
  }
}
