.complete-payment {
  .complete-payment-box {
    .top {
      text-align: center;
      margin-bottom: 30px;
    }
    p {
      color: gray;
    }
  }

  .home-btn {
    padding: 15px;
  }
}
