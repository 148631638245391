.homepage {
  input {
    margin-bottom: 10px;
    border: 1.2px solid rgba(6, 84, 116, 0.4);
    border-radius: 4px;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 2px;
    color: #424347;
  }

  input::placeholder {
    opacity: 0.6;
    text-align: center;
  }

  p {
    font-size: 26px;
    font-weight: 600;
  }

  .btn {
    font-weight: 800;
  }
}
