@import "./variables.scss";

$step_margin: 20px;
$step_height: 58px;

$step_margin_mobile: 16px;
$step_height_mobile: 40px;

$incomplete_bg_color: rgb(240, 240, 240);

.steps {
  display: block;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  .step {
    padding: 5px 5px 5px 25px;
    display: block;
    position: relative;
    float: left;
    width: calc(100% / 3 - 25px);
    // height: ;
    // line-height: ;
    margin-right: $step_margin;
    border-radius: 5px;

    &::before {
      content: "";
      position: absolute;
      bottom: -1.6px;
      right: -($step_margin - 2px);
      height: 0;
      width: 0;
      border-top: $step_height/2 solid transparent;
      border-bottom: $step_height/2 solid transparent;
      border-left: $step_margin solid $primary;
      border-radius: 3px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: -1px;
      height: 0;
      width: 0;
      border-top: $step_height/2 solid transparent;
      border-bottom: $step_height/2 solid transparent;
      border-left: $step_margin solid white;
      border-radius: 5px;
    }

    &:first-child {
      &::after {
        display: none;
      }
    }
    &:last-child {
      margin-right: 0;
      &::before {
        display: none;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    h5 {
      font-size: 14px;
      margin-bottom: 0px;
    }

    &.active {
      &::before {
        border-left-color: $alternate;
      }
      background-color: $alternate;
      border: none !important;
      h3,
      h5 {
        color: $primary;
      }
    }

    &.completed {
      background-color: $primary;
      border: 1px solid $primary;
      h3,
      h5 {
        color: white;
      }
    }
    &.incomplete {
      &::before {
        border-left-color: $incomplete_bg_color;
      }
      background-color: $incomplete_bg_color;
      // border: 1px solid $greyBorder;
      h3 {
        color: black;
      }
      h5 {
        color: $altText;
      }
    }
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  .steps {
    .step {
      text-align: center;
      width: calc(100% / 3 - 15px);

      h5 {
        display: none;
      }

      &::before {
        right: 2px - $step_margin_mobile;
        border-top: $step_height_mobile/2 solid transparent;
        border-bottom: $step_height_mobile/2 solid transparent;
        border-left: $step_margin_mobile solid $primary;
      }

      &::after {
        border-top: $step_height_mobile/2 solid transparent;
        border-bottom: $step_height_mobile/2 solid transparent;
        border-left: $step_margin_mobile solid white;
      }
    }
  }
}
