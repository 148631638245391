// Customize theme
$primary: #0999EB;
$secondary: #E5E5E5;
$alternate: #E6F5FE;
$success: #DEFBE6;
$info: #EDF5FF;
$warning: #FFF8E2;
$danger: #FFF1F1;

$greyBorder: #D2D6DC;
$altText: #6B7280;

@import "~bootstrap/scss/bootstrap";
